// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/commons/charts/TeamscaleChartsTemplate.soy

/**
 * @fileoverview Templates in namespace ts.commons.charts.TeamscaleChartTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './../LinkTemplate.soy.generated.js';


/**
 * @param {!$basicScaffolding.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $basicScaffolding = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $basicScaffolding$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.width, opt_data.height, opt_data.showZoom);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} width
 * @param {?} height
 * @param {?} showZoom
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $basicScaffolding$ = function($$areYouAnInternalCaller, $ijData, width, height, showZoom) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.charts.TeamscaleChartTemplate.basicScaffolding']) {
    return soy.$$stubsMap['ts.commons.charts.TeamscaleChartTemplate.basicScaffolding']({width: width, height: height, showZoom: showZoom}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div style="width: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(width)) + '; height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(height)) + '; position: relative"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.charts.TeamscaleChartTemplate.basicScaffolding ts/commons/charts/TeamscaleChartsTemplate.soy:18"' : '') + '>' + (showZoom ? '<div style="position: absolute; top: 0; bottom: 100px; width: 100%"></div><div style="position: absolute; height: 60px; bottom: 30px; width: 100%"></div>' : '<div style="position: absolute; top: 0; height: 100%; width: 100%"></div>') + '</div>');
};
export { $basicScaffolding as basicScaffolding };
export { $basicScaffolding$ as basicScaffolding$ };
/**
 * @typedef {{
 *  width: ?,
 *  height: ?,
 *  showZoom: ?,
 * }}
 */
$basicScaffolding.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $basicScaffolding.soyTemplateName = 'ts.commons.charts.TeamscaleChartTemplate.basicScaffolding';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $baselineTooltip = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.charts.TeamscaleChartTemplate.baselineTooltip']) {
    return soy.$$stubsMap['ts.commons.charts.TeamscaleChartTemplate.baselineTooltip'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div style="position: absolute; bottom: 50%; width: 100%; left: 0; height: 30px; text-align: center;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.charts.TeamscaleChartTemplate.baselineTooltip ts/commons/charts/TeamscaleChartsTemplate.soy:33"' : '') + '><span class="widget-baseline-tooltip" style="display: none;"></span></div>');
};
export { $baselineTooltip as baselineTooltip };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $baselineTooltip.soyTemplateName = 'ts.commons.charts.TeamscaleChartTemplate.baselineTooltip';
}


/**
 * @param {?$pieChartContainer.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $pieChartContainer = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $pieChartContainer$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.iconType, opt_data.deltaReferencePointInTime);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} iconType
 * @param {null|string|undefined=} deltaReferencePointInTime
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $pieChartContainer$ = function($$areYouAnInternalCaller, $ijData, iconType, deltaReferencePointInTime) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.charts.TeamscaleChartTemplate.pieChartContainer']) {
    return soy.$$stubsMap['ts.commons.charts.TeamscaleChartTemplate.pieChartContainer']({iconType: iconType, deltaReferencePointInTime: deltaReferencePointInTime}, $ijData);
  }
  soy.assertParamType(iconType == null || typeof iconType === 'string', 'iconType', iconType, '@param', 'null|string|undefined');
  soy.assertParamType(deltaReferencePointInTime == null || typeof deltaReferencePointInTime === 'string', 'deltaReferencePointInTime', deltaReferencePointInTime, '@param', 'null|string|undefined');
  let $output = '<div class="pie-chart-outer-container"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.charts.TeamscaleChartTemplate.pieChartContainer ts/commons/charts/TeamscaleChartsTemplate.soy:48"' : '') + '><div class="pie-chart-container" style="width: 100%; height: 100%"></div>' + (deltaReferencePointInTime ? '<div class="pie-chart-trend-text text-center"><strong>' + soy.$$escapeHtml(deltaReferencePointInTime) + '</strong></div>' : '');
  if (iconType) {
    const showWarning__soy19271 = iconType == 'warning';
    $output += '<i class="pie-chart-icon ' + (showWarning__soy19271 ? 'remove icon' : 'check circle icon') + (showWarning__soy19271 ? ' red' : ' green') + '"></i>';
  }
  $output += '</div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $pieChartContainer as pieChartContainer };
export { $pieChartContainer$ as pieChartContainer$ };
/**
 * @typedef {{
 *  iconType?: (null|string|undefined),
 *  deltaReferencePointInTime?: (null|string|undefined),
 * }}
 */
$pieChartContainer.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $pieChartContainer.soyTemplateName = 'ts.commons.charts.TeamscaleChartTemplate.pieChartContainer';
}


/**
 * @param {!$scatterPlotTooltip.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $scatterPlotTooltip = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.charts.TeamscaleChartTemplate.scatterPlotTooltip']) {
    return soy.$$stubsMap['ts.commons.charts.TeamscaleChartTemplate.scatterPlotTooltip'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {string} */
  const uniformPath = soy.assertParamType(typeof opt_data.uniformPath === 'string', 'uniformPath', opt_data.uniformPath, '@param', 'string');
  /** @type {!Array<string>} */
  const metricNames = soy.assertParamType(Array.isArray(opt_data.metricNames), 'metricNames', opt_data.metricNames, '@param', '!Array<string>');
  /** @type {!Array<string>} */
  const metricValues = soy.assertParamType(Array.isArray(opt_data.metricValues), 'metricValues', opt_data.metricValues, '@param', '!Array<string>');
  /** @type {string} */
  const linkToFileClassName = soy.assertParamType(typeof opt_data.linkToFileClassName === 'string', 'linkToFileClassName', opt_data.linkToFileClassName, '@param', 'string');
  let $output = '<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.charts.TeamscaleChartTemplate.scatterPlotTooltip ts/commons/charts/TeamscaleChartsTemplate.soy:79"' : '') + '><a class="' + soy.$$escapeHtmlAttribute(linkToFileClassName) + '" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.metricsShowingPerspective(soy.$$assignDefaults({uniformPath: uniformPath, project: project}, opt_data), $ijData))) + '">' + soy.$$escapeHtml(uniformPath) + '</a><table class="ui inverted very basic compact borderless marginless table">';
  const i19316_RangeEnd = metricNames.length;
  const i19316ListLen = Math.max(0, Math.ceil((i19316_RangeEnd - 0) / 1));
  for (let i19316Index = 0; i19316Index < i19316ListLen; i19316Index++) {
    const i19316Data = 0 + i19316Index * 1;
    $output += '<tr><td>' + soy.$$escapeHtml(metricNames[/** @type {?} */ (i19316Data)]) + '</td><td style="text-align: right">' + soy.$$escapeHtml(metricValues[/** @type {?} */ (i19316Data)]) + '</td></tr>';
  }
  $output += '</table></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $scatterPlotTooltip as scatterPlotTooltip };
/**
 * @typedef {{
 *  project: string,
 *  uniformPath: string,
 *  metricNames: !Array<string>,
 *  metricValues: !Array<string>,
 *  linkToFileClassName: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  resourceType?: (null|string|undefined),
 *  visibleMetric?: (?|undefined),
 *  targetPerspectiveName?: (null|string|undefined),
 *  partitions?: (!Array<string>|null|undefined),
 *  thresholdProfile?: (null|string|undefined),
 *  targetViewName?: (null|string|undefined),
 *  findingFilter?: (!Array<string>|null|undefined),
 *  selection?: (?|undefined),
 *  identifier?: (null|string|undefined),
 *  highlight?: (null|string|undefined),
 *  showCoverage?: (boolean|null|undefined),
 *  highlightMetric?: (boolean|null|undefined),
 * }}
 */
$scatterPlotTooltip.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $scatterPlotTooltip.soyTemplateName = 'ts.commons.charts.TeamscaleChartTemplate.scatterPlotTooltip';
}
